import type { CldVideoPlayerProps } from 'next-cloudinary'

export type VideoProps = {
  aspectRatio?: AspectRatio
  posterImagePublicId?: string
  posterSeconds?: string
  publicId?: CldVideoPlayerProps['src']
  wrapperClassName?: string
} & Omit<CldVideoPlayerProps, 'fontFace' | 'bigPlayButton' | 'logo' | 'src' | 'poster'>

export enum AspectRatio {
  ONE_ONE = '1:1',
  SIXTEEN_NINE = '16:9'
}
