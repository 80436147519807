import { CldVideoPlayer } from 'next-cloudinary'
import { AspectRatio, VideoProps } from 'components/video/types'

const Video = ({
  aspectRatio = AspectRatio.SIXTEEN_NINE,
  posterImagePublicId,
  posterSeconds,
  publicId,
  wrapperClassName,
  ...props
}: VideoProps) => {
  return (
    <div className={wrapperClassName}>
      <CldVideoPlayer
        key={publicId}
        src={publicId}
        bigPlayButton="init"
        logo={false}
        aspectRatio={aspectRatio}
        {...(!!posterImagePublicId && {
          poster: `https://res.cloudinary.com/bolt-energie/image/upload/f_auto/v1/${posterImagePublicId}.png`
        })}
        {...(!!posterSeconds && {
          poster: `https://res.cloudinary.com/bolt-energie/video/upload/so_${posterSeconds},f_auto/v1/${publicId}.png`
        })}
        {...props}
      />
    </div>
  )
}

export default Video
