import { memo } from 'react'
import { AdvancedImage, responsive, placeholder } from '@cloudinary/react'
import { getCloudinaryImage } from 'utils/cloudinary'
import { ImageProps } from 'components/img/types'
import styles from './Img.module.scss'
import classNames from 'classnames'

const Img = memo(({ publicId, fill, priority, objectFit = 'cover', objectPosition, steps = 200, mask, ...imgProps }: ImageProps) => {
  // Constants
  const { style } = imgProps

  return (
    <AdvancedImage
      {...imgProps}
      className={classNames(styles.img, { [styles.fill]: fill, [styles[`mask-${mask}`]]: !!mask }, imgProps.className)}
      decoding={!priority ? 'async' : undefined}
      fetchpriority={priority ? 'high' : undefined}
      style={{ objectFit, objectPosition, ...style }}
      cldImg={getCloudinaryImage(publicId)}
      loading={!priority ? 'lazy' : undefined}
      plugins={[responsive({ steps }), placeholder({ mode: 'blur' })]}
    />
  )
})

Img.displayName = 'Img'

export default Img
