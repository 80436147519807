import { Cloudinary, CloudinaryImage } from '@cloudinary/url-gen'
import { CLOUDINARY_CLOUD_NAME } from 'constants/envs'

// Create and confige Cloudinary instance
const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_CLOUD_NAME
  }
})

export const getCloudinaryImage = (publicId: string): CloudinaryImage => cld.image(publicId).format('webp')
